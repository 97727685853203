export enum TipoAzioniPartitaCampionatoSquadre {
  MODIFICA_RISULTATO = "modifica_risultato",
  CONFERMA_RISULTATO = "conferma_risultato",
}
export enum Settori {
  TENNIS = 1,
  PADEL = 2,
  BEACH_TENNIS = 3,
  FITP_JUNIOR_PROGRAM = 4,
}
export enum STATI_ASSISTENZA {
  Nuova = "info",
  Presaincarico = "primary",
  Inlavorazione = "warning",
  Risolta = "success",
  Annullata = "danger",
}
export enum DisciplinaFITP {
  TENNIS = 4332,
  PADEL = 172,
  BEACH_TENNIS = 171,
  PICKLEBALL = 174,
  "Tennis - Squadre fasi finali" = 14332,
  "Padel - Squadre fasi finali" = 10172,
  "Beach tennis - Squadre fasi finali" = 10171,
}
export enum RUOLI {
  SVILUPPATORE = 1,
  COORDINATORE = 2,
  FIDUCIARIO = 3,
  SOCIETA = 4,
  INSEGNANTE = 5,
  GIOCATORE = 6,
  AMMINISTRATORE = 7,
  COMITATO = 8,
}
export enum STATI_TORNEO {
  DA_APPROVARE = 1,
  RIFIUTATO = 2,
  APPROVATO = 3,
}
export enum COMANDI_ENABLED {
  APPROVA_E_RIFIUTA_TORNEO = "fnTorneiApprovaRifiuta",
  VISUALIZZA_LISTA_ISCRIZIONI_TORNEI_SQUADRE = "fnSquadreListaIscrizioniTornei",
  AGGIUNGI_ISCRIZIONE_SQUADRA = "fnSquadreAggiungiIscrizioneListaIscrizioni",
  GESTIONE_BOTTONI_CAMPIONATI_SQUADRE = "fnSquadreCampionatiTorneiTabelloniGeneraResetApprova",
  ASSISTENZE_MODIFICA = "fnAssistenzeModifica",
  GESTIONE_COMUNICAZIONI = "fnComunicazioniGestisci",
}
export enum Liste {
  CIRCUITI_LIST = "circuiti_list",
  TORNEI_MANIFESTAZIONI_LIST = "manifestazioni_list",
  SFIDE_LIST = "sfide_list",
  CAMPIONATI_LIST = "campionati_list",
  ANAGRAFICA_CIRCOLI_LIST = "anagrafica_circoli_list",
  SQUADRE_CAMPIONATI_SQUADRE_LIST = "squadre_list",
  ISCRIZIONI_TORNEO_LIST = "iscrizioni_list",
  ISCRIZIONI_ADD_LISTA_GIOCATORI = "iscrizioni_add_list_giocatori",
  LISTA_TESSERATI = "lista_tesserati",
  LISTA_TESSERATI_SFIDE = "lista_tesserati_sfide",
  NEWS_GIOCATORI_LIST = "news_giocatori_list",
  LISTA_COORDINATORI = "lista_coordinatori",
  LISTA_FIDUCIARI = "lista_fiduciari",
  SQUADRE_CAMPIONATI_TORNEI_LIST = "squadre_campionati_tornei_list",
  ISCRIZIONI_CAMPIONATO_SQUADRE_LIST = "iscrizioni_campionato_squadre_list",
  ISCRIZIONI_CAMPIONATO_SQUADRE_ADD = "iscrizioni_campionato_squadre_add",
  ANAGRAFICA_GIOCATORI_LIST = "anagrafica_giocatori_list",
  BANNER_TORNEO_LIST = "banner_torneo_list",
  INSEGNANTI_PROVINCE_REGIONI_LIST = "insegnanti_province_regioni_list",
  CLASSIFICHE_SOCIALI_LIST = "classifiche_sociali_list",
  CONTROLLER_ISCRITTI_CONSOLIDATI_ANNO_REGIONI_LIST = "controller_iscritti_consolidati_anno_regioni_list",
  CONTROLLER_LIST = "controller_list",
  CONTROLLER_NUOVE_TESTE_CONSOLIDATI_TOTALE_ANNI_LIST = "CONTROLLER_NUOVE_TESTE_CONSOLIDATI_TOTALE_ANNI_LIST",
  ASSISTENZA_ASSISTENZE_LIST = "ASSISTENZE_LIST",
  COMUNICAZIONE_COMUNICAZIONI_LIST = "COMUNICAZIONI_LIST",
  SISTEMA_RUOLI_INSEGNANTI_LIST = "SISTEMA_RUOLI_INSEGNANTI_LIST",
  LISTA_TORNEI_NON_INVIATI = "LISTA_TORNEI_NON_INVIATI",
}
export enum API {
  LOOKUP_TORNEI_PREMI = "/v1/lookup/tornei/premi/get",
  LOOKUP_TORNEI_TABELLONI_DIMENSIONI = "/v1/tornei/tabelloni/dimensioni/get",
  LOOKUP_TORNEI_SETTORI_GRUPPI_P_RANGE = "v1/tornei/settori-gruppi/tipologie/power-range/get",
  LOOKUP_TORNEI_SETTORI_GRUPPI = "v1/tornei/settori-gruppi/get",
  LOOKUP_TORNEI_TIPOLOGIE = "/v1/lookup/tornei/tipologie/get",
  LOOKUP_IMPIANTI = "/v1/lookup/societa/impianti/get",
  LOOKUP_SOCIETA = "/v1/lookup/societa/search",
  LOOKUP_FIDUCIARI = "/v1/lookup/fiduciari/search",
  LOOKUP_COORDINATORI = "/v1/lookup/coordinatori/search",
  LOOKUP_TORNEI_EVENTI_CALENDARIO = "v1/lookup/tornei-eventi/calendario/get",
  LOOKUP_REGIONI_PROVINCIE = "/v1/lookup/regioni-provincie/get",
  LOOKUP_REGIONI_PROVINCIE_FE = "/v1/lookup/frontend/regioni-provincie/get",
  LOOKUP_CIRCUITI_PRIMARI = "v1/lookup/circuiti-primari/get",
  LOOKUP_CIRCUITI = "/v1/lookup/circuiti/get",
  LOOKUP_GET = "/v1/lookup/get",
  LOOKUP_TORNEI_GIRONI_COMBINAZIONI = "/v1/lookup/tornei/gironi-combinazioni/get",
  LOOKUP_SQUADRE_GIRONI_COMBINAZIONI = "/v1/lookup/squadre/gironi/combinazioni/get",
  LOOKUP_SQUADRE_CAMPIONATI = "/v1/lookup/squadre/campionati/get",
  LOOKUP_SQUADRE_ZONA = "/v1/lookup/squadre/campionati/zone/get",
  LOOKUP_SQUADRE_CAMPIONATI_REGIONI_ZONE_GET = "/v1/lookup/squadre/campionati/regioni/zone/get",
  LOOKUP_SQUADRA_GIORNI_DISPONIBILI = "/v1/lookup/squadre/giorni_disponibili/get",
  LOOKUP_SQUADRA_ORARI = "v1/lookup/squadre/orari/get",
  LOOKUP_SQUADRE_GIOCATORI = "v1/lookup/squadre/giocatori/search",
  LOOKUP_SQUADRE_SEARCH = "/v1/lookup/squadre/search",
  LOOKUP_SQUADRE_FASI = "/v1/lookup/squadre/fasi/get",
  LOOKUP_LISTA_INSEGNANTI = "/v1/sistema/insegnanti/list",
  LOOKUP_RICERCA_PER_ISCRIZIONE = "/v1/lookup/squadre/ricerca/per-iscrizione/get",
  LOOKUP_SETTORI_TESSERAMENTO_CATEGORIE_ETA_GET = "v1/settori/tesseramento/categorie-eta/get",
  LOOKUP_SOCIETA_IMPIANTI_SEARCH = "v1/lookup/societa/impianti/search",
  LOOKUP_RICERCA_GIOCATORI_GET = "v1/lookup/ricerca-giocatori/get",
  LOOKUP_SISTEMA_RUOLI_GET = "v1/lookup/sistema/ruoli/get",
  MENU_GET = "/v1/sistema/menu/get",
  SISTEMA_COORDINATORI_SETTORI_SOCIETA_VIEW = "/v1/sistema/coordinatori/settori/societa/view",
  // circuiti
  CIRCUITI_LIST = "/v1/tornei/circuiti/list",
  ADD_CIRCUITO = "/v1/tornei/circuiti/add",
  EDIT_CIRCUITO = "/v1/tornei/circuiti/edit",
  DEL_CIRCUITO = "/v1/tornei/circuiti/del",
  CIRCUITO_VIEW = "/v1/tornei/circuiti/view",
  //campionati
  CAMPIONATI_LIST = "/v1/squadre/campionati/list",
  CAMPIONATO_VIEW = "/v1/squadre/campionati/view",
  EDIT_SQUADRE_CAMPIONATI = "/v1/squadre/campionati/edit",
  ADD_SQUADRE_CAMPIONATI = "/v1/squadre/campionati/add",
  DEL_SQUADRE_CAMPIONATI = "/v1/squadre/campionati/del",
  //SFIDE
  SFIDE_LIST = "/v1/sfide/list",
  SFIDE_VIEW = "/v1/sfide/view",
  SFIDE_ADD = "/v1/sfide/add",
  SFIDE_DEL = "/v1/sfide/del",
  SFIDE_PUBBLICA = "/v1/sfide/pubblica",
  SFIDE_GIOCATORE_ADD = "/v1/sfide/giocatori/add",
  SFIDE_GIOCATORE_DEL = "/v1/sfide/giocatori/del",
  SFIDE_RISULTATI_ADD = "/v1/sfide/risultato/add",
  LISTA_TESSERATI_SFIDE = "/v1/sfide/tesserati/ricerca",

  //tornei - manifestazione
  TORNEI_MANIFESTAZIONI_REGOLAMENTO = "v1/lookup/regolamento/settore/get",
  TORNEI_MANIFESTAZIONI_LIST = "/v1/tornei/manifestazioni/list",
  TORNEI_MANIFESTAZIONI_ADD = "/v1/tornei/manifestazioni/add",
  TORNEI_MANIFESTAZIONI_VIEW = "/v2/tornei/manifestazioni/view",
  TORNEI_MANIFESTAZIONI_EDIT = "/v2/tornei/manifestazioni/edit",
  TORNEI_MANIFESTAZIONI_DELETE = "/v1/tornei/manifestazioni/delete",
  TORNEI_MANIFESTAZIONI_BY_PERIODO = "/v1/tornei/manifestazioni/by-periodo/list",
  TORNEI_MANIFESTAZIONE_CHECK_SCUOLA = "/v1/verifiche/check/settori/richiesta-manifestazione",
  TORNEI_CLASSIFICA_LIST = "/v1/tornei/gironi/classifica/list",
  TORNEI_SET_GIOCATORI_TABELLONI_FINALE = "/v1/tornei/gironi/aggiorna",
  DETTAGLIO_TORNEO = "/v1/tornei/titoli/sezione/view",

  TORNEI_TORNEO_DELETE = "v1/tornei/torneo/del",
  TORNEI_TORNEO_EDIT = "v2/tornei/torneo/edit",
  TORNEI_TORNEO_ADD = "v2/tornei/torneo/add",
  TORNEI_TORNEO_SOCIETA_EDIT = "v1/tornei/societa/edit",
  TORNEI_TORNEO_APPROVA = "v1/tornei/torneo/approva",
  TORNEI_TORNEO_RIFIUTA = "v1/tornei/torneo/rifiuta",
  TORNEI_INVIO_AVVISO_TORNEO = "/v1/tornei/invio-avviso/tesserati-societa/add",
  // tornei iscrizioni
  ISCRIZIONI_TORNEO_LIST = "/v1/tornei/iscrizioni/list",
  ISCRIZIONI_ADD_LISTA_GIOCATORI = "/v1/tornei/iscrizioni/ricerca-giocatori",
  ISCRIZIONI_ADD_GIOCATORE = "/v2/tornei/iscrizioni/add",
  ISCRIZIONI_SET_CARTELLINO = "/v1/tornei/ammonizioni/upsert",
  ISCRIZIONI_DEL_GIOCATORE = "/v1/tornei/iscrizioni/del",
  ISCRIZIONI_SOSTITUZIONE = "/v2/tornei/iscrizioni/sostituzione/add",
  CHECK_ISCRIZIONI_APERTE = "/v1/verifiche/check/torneo/iscrizioni/aperte",
  CHECK_DISISCRIZIONI_APERTE = "/v1/verifiche/check/torneo/disiscrizioni/aperte",

  TORNEI_RISULTATO_EDIT = "/v1/tornei/tabelloni/risultato/edit",
  CHECK_GESTIONE_TABELLONE = "/v1/verifiche/check/tornei/tabelloni/puo-gestire",
  //tornei tabelloni
  TORNEI_TABELLONE_VIEW = "/v1/tornei/tabelloni/view",
  TORNEI_TABELLONE_GENERA_SEZIONE_FINALE = "/v1/tornei/tabelloni/genera_fase_finale",
  TORNEI_TABELLONE_ELIMINAZIONE_DIRETTA_GENERA = "/v1/tornei/tabelloni/genera-eliminazione-diretta",
  TORNEI_TABELLONE_SOSTITUISCI_POSIZIONE_GIOCATORE = "/v1/tornei/tabelloni/sostituisci-posizione-giocatore",
  TORNEI_TABELLONE_APPROVA = "/v1/tornei/tabelloni/approva",
  TORNEI_TABELLONE_SPOSTA_GIOCATORI_E_GENERA = "v1/daimplementare",
  TORNEI_TABELLONE_RESET = "v1/tornei/tabelloni/reset",

  // TORNEI GIRONI
  TORNEI_GIRONI_GENERA = "/v1/tornei/gironi/genera",
  TORNEI_GIRONI_LIST = "/v1/tornei/gironi/list",
  TORNEI_GIRONI_EDIT = "/v1/tornei/gironi/edit",
  TORNEI_TABELLONI_GIRONI_GENERA = "/v1/tornei/tabelloni/genera-gironi",
  TORNEI_GIRONI_RESET = "v1/tornei/gironi/reset",

  //anagrafica circoli  MANCA LA ANAGRAFICA CIRCOLI VIEW!
  ANAGRAFICA_CIRCOLI_LIST = "/v1/anagrafica/societa/list",
  ANAGRAFICA_CIRCOLI_ADD = "/v1/anagrafica/circolo/add",
  ANAGRAFICA_CIRCOLI_EDIT = "/v1/anagrafica/circolo/edit",
  // SQUADRE CAMPIONATI SQUADRA
  SQUADRE_LIST = "/v1/squadre/list", // non funziona. chiede in input campionato
  SQUADRE_CAMPIONATI_SQUADRE_LIST = "/v1/squadre/campionati/squadra/list",
  SQUADRE_CAMPIONATI_SQUADRE_VIEW = "/v1/squadre/campionati/squadra/view",
  SQUADRE_CAMPIONATI_SQUADRE_EDIT = "/v1/squadre/campionati/squadra/edit",
  SQUADRE_CAMPIONATI_SQUADRE_ADD = "/v1/squadre/campionati/squadra/add",
  SQUADRE_CAMPIONATI_SQUADRE_DEL = "/v1/squadre/campionati/squadra/del",
  SQUADRE_CAMPIONATI_SQUADRA_GIOCATORE_CHECK = "/v1/squadre/campionati/squadra/giocatore/check",
  SQUADRE_CAMPIONATI_TORNEI_LIST = "/v1/squadre/tornei/list",
  SQUADRE_CAMPIONATI_SPOSTA_INCONTRI = "v1/squadre/tabelloni/incontri/sposta",
  SQUADRE_CAMPIONATI_SPOSTA_SQUADRE = "/v1/squadre/tabelloni/incontri/sposta-squadre",
  SQUADRE_CAMPIONATI_CONFERMA_RISULTATO_PARTITA = "/v1/squadre/tabelloni/risultati/conferma",
  // CENTRI TENNIS
  CENTRI_TENNIS_SEARCH = "/v1/lookup/centri-tennis/search",
  //GIOCATORI
  DETTAGLIO_TESSERA = "/v1/tesserato/tessera/view",
  LISTA_TESSERATI = "/v1/tesserati/ricerca",
  //circoli
  COORDINATORI_VIEW = "v1/sistema/coordinatori/settori-societa/view",
  CHANGE_COORDINATORI = "/v1/sistema/coordinatori/settori-societa/edit",
  CHANGE_COORDINATORI_MULTIPLI = "/v1/sistema/coordinatori/settori-societa/multiple/edit",
  ANAGRAFICA_SOCIETA_COORDINATORI_LIST = "v1/anagrafica/societa/coordinatori/list",
  // NEWS
  NEWS_GIOCATORI_ADD = "/v1/news/add",
  NEWS_GIOCATORI_EDIT = "/v1/news/edit",
  NEWS_GIOCATORI_LIST = "/v1/news/list",
  NEWS_GIOCATORI_VIEW = "/v1/news/view",
  NEWS_GIOCATORI_VIEW_GUID = "/v1/news/frontend/view",
  NEWS_GIOCATORI_APPROVA = "/v1/news/approva",
  //coordinatori
  LISTA_COORDINATORI = "/v1/anagrafica/coordinatori/list",
  //fiduciari
  LISTA_FIDUCIARI = "/v1/anagrafica/fiduciari/list",

  //tornei non inviati a udg o mgmt
  LISTA_TORNEI_NON_INVIATI = "/v1/verifiche/tornei-non-inviati/list",
  // campionati squadre
  DETTAGLIO_CAMPIONATO_SQUADRE = "/v1/squadre/tornei/titoli_sezione/view_edit",
  CAMPIONATO_SQUADRE_GIRONI_GENERA = "/v1/squadre/gironi/genera",
  CAMPIONATO_SQUADRE_GIRONI_LIST = "/v1/squadre/gironi/list",
  CAMPIONATO_SQUADRE_GIRONI_EDIT = "/v1/squadre/gironi/edit",
  CAMPIONATO_SQUADRE_GIRONI_GENERA_TABELLONI = "/v1/squadre/tabelloni/genera/gironi",
  CAMPIONATO_SQUADRE_GIRONI_RESET = "/v1/squadre/gironi/reset",
  CAMPIONATO_SQUADRE_GIRONI_TABELLONI_VIEW = "/v1/squadre/tabelloni/view_edit",
  CAMPIONATO_SQUADRE_GIRONI_SCHIERAMENTI_VIEW = "/v1/squadre/tabelloni/schieramenti/view_edit",
  CAMPIONATO_SQUADRA_GIRONI_SCHIERAMENTI_EDIT = "/v1/squadre/tabelloni/schieramenti/edit",
  CAMPIONATO_SQUADRA_TABELLONI_RESET = "/v1/squadre/tabelloni/reset",
  CAMPIONATO_SQUADRA_TABELLONI_SCHIERAMENTI_CHECK_PARTITA_GENERA = "/v1/verifiche/squadre/tabelloni/schieramenti/partite-genera/check",
  CAMPIONATO_SQUADRA_TABELLONI_SCHIERAMENTI_PARTITA_GENERA = "/v1/squadre/tabelloni/schieramenti_partite/genera",
  CAMPIONATI_SQUADRA_TABELLONI_INCONTRI_EDIT = "/v1/squadre/tabelloni/incontri/edit",
  CAMPIONATI_SQUADRA_TABELLONI_VITTORIA_PER_ASSENZA_EDIT = "/v1/squadre/tabelloni/incontri/vincitore/edit",
  CAMPIONATO_SQUADRE_TABELLONI_RISULTATO_EDIT = "/v1/squadre/tabelloni/risultato/edit",
  CAMPIONATO_SQUADRA_CLASSIFICA_GIRONI_LIST = "/v1/squadre/gironi/classifiche/list",
  CAMPIONATO_SQUADRA_TABELLONI_PUO_GESTIRE = "/v1/verifiche/check/squadre/tabelloni/puo-gestire",
  //ELIMINAZIONE DIRETTA CAMPIONATO A SQUADRE
  CAMPIONATO_SQUADRA_ELIMINAZIONE_DIRETTA_GENERA = "/v1/squadre/tabelloni/eliminazione_diretta/genera",
  CAMPIONATO_SQUADRA_ELIMINAZIONE_DIRETTA_APPROVA = "v1/squadre/tabelloni/approva",
  CAMPIONATO_SQUADRA_ELIMINAZIONE_DIRETTA_SET_IMPIANTO_COORDINATORE = "/v1/squadre/tornei/edit",
  CAMPIONATO_SQUADRA_ELIMINAZIONE_DIRETTA_EDIT_TORNEO = "/v1/squadre/tornei/edit",
  //ISCRIZIONI CAMPIONATO SQUADRE
  ISCRIZIONI_CAMPIONATO_SQUADRE_LIST = "/v1/squadre/iscrizioni/list",
  ISCRIZIONI_CAMPIONATO_SQUADRE_ADD = "/v1/squadre/iscrizioni/add",
  ISCRIZIONI_CAMPIONATO_SQUADRE_RICERCA = "/v1/lookup/squadre/search",
  ISCRIZIONE_DEL_SQUADRA = "/v1/squadre/iscrizioni/del",
  //ANAGRAFICA PERSONE
  ANAGRAFICA_GIOCATORI_LIST = "/v1/anagrafica/persone/list",
  ANAGRAFICA_PERSONA_VIEW = "/v1/anagrafica/persona/view",
  ANAGRAFICA_PERSONA_EDIT = "/v1/anagrafica/persona/edit",
  //ISCRIZIONE CIRCOLO
  ISCRIVI_PERSONA_DA_COD_ANAGRAFICA = "v1/tesserati/iscrivi",
  CHECK_PERSONA_ISCRITTA = "v1/verifiche/check/iscrizione/giocatore",
  //SINCRONIZZAZIONE TESSERA
  SINCRONIZZA_TESSERA = "/v1/anagrafica/persona/tessere/sincronizza",
  //MODIFICA FOTOTESSERA
  MODIFICA_FOTOTESSERA = "v1/anagrafica/persona/fototessera/edit",
  //LISTA BANNER
  BANNER_TORNEO_LIST = "/v1/tornei/banner/list",
  BANNER_ADD = "/v1/tornei/banner/add",
  BANENR_EDIT = "/v1/tornei/banner/edit",
  BANNER_DELETE = "/v1/tornei/banner/del",
  //INSEGNANTI
  SISTEMA_RUOLI_INSEGNANTI_LIST = "v1/sistema/ruoli/insegnanti/list",
  SISTEMA_INSEGNANTI_PROVINCE_EDIT = "/v1/sistema/insegnati/province/edit",
  INSEGNANTI_PROVINCE_REGIONI_LIST = "/v1/sistema/insegnati/province/list",
  INSEGNANTI_AGGIUNGI = "/v1/sistema/insegnati/province/add",
  INSEGNANTI_DELETE = "/v1/sistema/insegnati/province/del",
  //classifiche sociali
  CLASSIFICHE_SOCIALI_LIST = "v1/classifica/sociale/list",
  // VERIFICA L'AFFILIAZIONE DI UNA SOCIETA IN UN DETERMINATO ANNO.
  // RICHIEDE ID SOC E ANNO.
  // POTREBBE ESSERCI LA NECCESSITA DI FARMI MANDARE ID_SOC DA DB
  // PER ORA NON VIENE USATA
  VERIFICA_AFFILIAZIONE_SOCIETA = "/v1/check/societa/affiliazione",
  // CREAZIONE UTENZA
  CREA_UTENZA = "/v1/sistema/utenza/add",
  //CONTROLLER
  CONTROLLER_LIST = "/v1/controller/iscritti/consolidati/globali/list",
  CONTROLLER_TOTALE_ANNI = "/v1/controller/iscritti/consolidati/totale-anni/list",
  // controller anno-regioni
  CONTROLLER_ISCRITTI_CONSOLIDATI_ANNO_REGIONI_LIST = "/v1/controller/iscritti/consolidati/anno-regioni/list",
  CONTROLLER_ISCRITTI_CONSOLIDATI_REGIONE_PROVINCIE_LIST = "/v1/controller/iscritti/consolidati/regione-provincie/list",
  CONTROLLER_ISCRITTI_CONSOLIDATI_PROVINCIA_SOCIETA_LIST = "/v1/controller/iscritti/consolidati/provincia-societa/list",
  // controller
  CONTROLLER_ISCRITTI_CONSOLIDATI_COORDINATORE_LIST = "v1/controller/iscritti/consolidati/coordinatore/list",
  CONTROLLER_ISCRITTI_CONSOLIDATI_COORDINATORE_PROVINCIE_LIST = "/v1/controller/iscritti/consolidati/coordinatore-provincie/list",
  CONTROLLER_ISCRITTI_CONSOLIDATI_COORDINATORE_SOCIETA_LIST = "/v1/controller/iscritti/consolidati/coordinatore-societa/list",
  // controller programmati
  CONTROLLER_ISCRITTI_PROGRAMMATI_ANNO_REGIONI_LIST = "/v1/controller/iscritti/programmati/anno-regioni/list",
  CONTROLLER_ISCRITTI_PROGRAMMATI_REGIONE_PROVINCIE_LIST = "/v1/controller/iscritti/programmati/regione-provincie/list",
  CONTROLLER_ISCRITTI_PROGRAMMATI_PROVINCIA_SOCIETA_LIST = "/v1/controller/iscritti/programmati/provincia-societa/list",
  // controller
  CONTROLLER_ISCRITTI_PROGRAMMATI_COORDINATORE_LIST = "v1/controller/iscritti/programmati/coordinatore/list",
  CONTROLLER_ISCRITTI_PROGRAMMATI_COORDINATORE_PROVINCIE_LIST = "/v1/controller/iscritti/programmati/coordinatore-provincie/list",
  CONTROLLER_ISCRITTI_PROGRAMMATI_COORDINATORE_SOCIETA_LIST = "/v1/controller/iscritti/programmati/coordinatore-societa/list",
  // controller squadre
  CONTROLLER_CAMPIONATI_SQUADRA = "/v1/controller/campionati/iscrizioni/list",
  // CONTROLLER NUOVE TESTE
  CONTROLLER_NUOVE_TESTE_CONSOLIDATI_TOTALE_ANNI_LIST = "/v1/controller/nuove-teste/consolidati/totale-anni/list",
  CONTROLLER_NUOVE_TESTE_CONSOLIDATI_ANNO_REGIONI_LIST = "/v1/controller/nuove-teste/consolidati/anno-regioni/list",
  CONTROLLER_NUOVE_TESTE_CONSOLIDATI_REGIONE_PROVINCIA_LIST = "/v1/controller/nuove-teste/consolidati/regione-provincie/list",
  CONTROLLER_NUOVE_TESTE_CONSOLIDATI_PROVINCIA_SOCIETA_LIST = "/v1/controller/nuove-teste/consolidati/provincia-societa/list",

  //CONTROLLER DOWNLOAD
  CONTROLLER_ISCRITTI_CONSOLIDATI_COORDINATORE_CSV_DOWNLOAD = "/v1/controller/iscritti-consolidati/coordinatore/csv/get",
  CONTROLLER_ISCRITTI_PROGRAMMATI_COORDINATORE_CSV_DOWNLOAD = "/v1/controller/iscritti-programmati/coordinatore/csv/get",
  CONTROLLER_ISCRITTI_PROGRAMMATI_CSV_DOWNLOAD = "/v1/controller/iscritti-programmati/csv/get",
  CONTROLLER_ISCRITTI_CONSOLIDATI_CSV_DOWNLOAD = "/v1/controller/iscritti-consolidati/csv/get",
  CONTROLLER_ISCRITTI_CAMPIONATI_CSV_DOWNLOAD = "/v1/controller/campionati_iscrizioni/csv/get",
  CONTROLLER_NUOVE_TESTE_CSV_DOWNLOAD = "/v1/controller/nuove-teste/csv/get",
  // STAMPE PDF
  TORNEI_TABELLONI_STAMPA_PDF_GET = "v1/tornei/tabelloni/stampa_pdf/get",

  TORNEI_GIRONI_STAMPA_GET = "v1/tornei/gironi/stampa/get",
  TORNEI_SQUADRE_TABELLONI_STAMPA_GET = "/v1/squadre/tabelloni/stampa-pdf/get",
  TORNEI_SQUADRE_GIRONI_STAMPA_GET = "/v1/squadre/gironi/stampa-pdf/get",
  TORNEI_MANIFESTAZIONI_LOCANDINA_GET = "v1/tornei/manifestazioni/locandina/get",
  TORNEI_ISCRIZIONI_ESPORTAZIONE_CSV_GET = "/v1/tornei/iscrizioni/esportazione_csv/get",
  //ASSISTENZA
  ASSISTENZA_ASSISTENZE_LIST = "/v1/assistenza/assistenze/list",
  ASSISTENZA_ASSISTENZE_VIEW = "/v1/assistenza/assistenze/view-edit",
  ASSISTENZA_ASSISTENZE_ADD = "/v1/assistenza/assistenze/add",
  ASSISTENZA_ASSISTENZE_EDIT = "/v1/assistenza/assistenze/edit",
  ASSISTENZA_ASSISTENZE_RISPOSTE_ADD = "/v1/assistenza/assistenze/risposte/add",
  ASSISTENZA_ASSISTENZE_ALLEGATO_DEL = "/v1/assistenza/assistenze/allegato/del",
  ASSISTENZA_ASSISTENZE_ALLEGATO_ADD = "/v1/assistenza/assistenze/allegati/add",
  // DOWNLOAD
  ALLEGATO_DOWNLOAD = "/v1/allegato/download",
  // COMUNICAZIONI
  COMUNICAZIONE_COMUNICAZIONI_LIST = "v1/comunicazioni/list",
  COMUNICAZIONE_COMUNICAZIONI_VIEW = "v1/comunicazioni/view",
  COMUNICAZIONE_COMUNICAZIONI_ADD = "v1/comunicazioni/add",
  COMUNICAZIONE_COMUNICAZIONI_ALLEGATO_ADD = "/v1/comunicazioni/allegati/add",
  COMUNICAZIONE_COMUNICAZIONI_ALLEGATO_DEL = "/v1/comunicazioni/allegati/del",
  COMUNICAZIONE_COMUNICAZIONI_EDIT = "v1/comunicazioni/edit",
  COMUNICAZIONE_COMUNICAZIONI_PRESA_VISIONE = "v1/comunicazioni/presa-visione/get",
}
